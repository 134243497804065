/*----------------------------
    Advance Pricing Table  
------------------------------*/
.advance-pricing {
    background: var(--color-blackest);
    border-radius: 10px;
    .pricing-left {
        height: 100%;
        background: var(--color-lessdark);
        padding: 60px 50px;
        border-radius: 10px 0 0 10px;
        .main-title {
            margin-bottom: 10px;
        }
        .description {

        }
        .price-wrapper {
            .price-amount {
                font-size: 60px;
                text-align: center;
                display: block;
                color: var(--color-lightest);
                font-weight: 700;
                sup {
                    font-size: 16px;
                    top: -34px;
                }
            }
        }
        .btn-default {
            width: 100%;
            margin: 10px 0;
        }
        .rating {
            text-align: center;
            margin-top: 5px;
            a {
                font-weight: 700;
                color: #f1b304;
                svg {
                    fill: #f1b304;
                }
            }
        }
        .subtitle {
            display: block;
            color: var(--color-body);
            text-align: center;
        }
    }

    .pricing-right {
        padding: 60px 30px;
        .plan-offer-list {
            @extend %liststyle;
        }

        .price-title {
            font-size: 20px;
            margin-bottom: 10px;
            li {
                font-size: 16px;
                margin: 8px 0;
            }
        }
    }
}



















